import React from 'react'
import Layout from '../hoc/Layout/Layout'
import Fields from '../components/Pages/Fields/Fields'
import SEO from '../components/seo'

export default () => (
  <Layout>
    <SEO
      title="Project Management Fields | Knowledge Fields"
      keywords={['PMP Fields', 'Knowledge Areas', 'Project Management Fields']}
      description="All Project Management Fields, PMP Knowledge Areas Questions"
    />
    <Fields />
  </Layout>
)
