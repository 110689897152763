import React, { Component } from 'react'
import classes from './Field.module.css'
import lockIcon from '../../../images/icons/lock.svg'
import lockOpenIcon from '../../../images/icons/lock_open.svg'
import closeIcon from '../../../images/icons/close.svg'
import { connect } from 'react-redux'
import * as actions from '../../../store/actions/index'
import Tooltip from '../../UI/Tooltip/Tooltip'

class Field extends Component {
  state = {
    isLocked: this.props.text === this.props.lockedField,
    icon: this.props.text === this.props.lockedField ? lockIcon : lockOpenIcon
  }

  componentDidMount() {
    if (this.props.lockedField === null) {
      const t = setInterval(() => {
        if (this.props.lockedField !== null) {
          clearInterval(t)

          this.setState({
            icon:
              this.props.text === this.props.lockedField
                ? lockIcon
                : lockOpenIcon
          })
        }
      }, 10)
    }
  }

  handleFieldClick = () => {
    if (this.state.isLocked) this.props.onFieldRelease()
    else this.props.onFieldLock(this.props.text)

    this.setState(prevState => {
      return {
        isLocked: !prevState.isLocked,
        icon: prevState.isLocked ? lockOpenIcon : lockIcon
      }
    })
  }

  handleMouseEnter = () => {
    this.setState({ icon: closeIcon })
  }

  handleMouseLeave = () => {
    this.setState({ icon: lockIcon })
  }

  componentDidUpdate() {
    if (this.props.isExam && this.state.isLocked)
      this.setState({ isLocked: false, icon: lockOpenIcon })
  }

  render() {
    let img = (
      <img className={classes.Img} src={this.state.icon} alt="lock icon" />
    )

    let tooltipTitle = ''
    let onClick = () => {}

    if (this.props.isAuthenticated) {
      if (this.props.paidAccessUntil > Date.now()) {
        if (this.props.isExam) {
          tooltipTitle = 'You cannot lock the field on Mock Exam'
        } else {
          tooltipTitle = this.props.lockedField
            ? 'You can unlock the field'
            : 'You can lock the field'
          onClick = this.handleFieldClick
        }
      } else {
        tooltipTitle = 'Replenish paid access'
      }
    } else {
      tooltipTitle = 'Sign in to lock the field'
    }

    let field = (
      /*eslint-disable */
      <h2
        className={classes.Text}
        onClick={onClick}
        onMouseEnter={this.state.isLocked ? this.handleMouseEnter : null}
        onMouseLeave={this.state.isLocked ? this.handleMouseLeave : null}
      >
        <Tooltip title={tooltipTitle}>
          <span
            className={
              this.props.text === this.props.lockedField ? classes.Locked : null
            }
          >
            {img}
            {this.props.text}
          </span>
        </Tooltip>
      </h2>
      /*eslint-disable */
    )

    return <>{field}</>
  }
}

const mapStateToProps = state => {
  return {
    lockedField: state.field.locked,
    isExam: state.exam.exam !== null,
    isAuthenticated: state.auth.token !== null,
    paidAccessUntil: state.user.paidAccessUntil
  }
}

const mapActionToProps = dispatch => {
  return {
    onFieldLock: field => dispatch(actions.fieldLock(field)),
    onFieldRelease: () => dispatch(actions.fieldRelease())
  }
}

export default connect(mapStateToProps, mapActionToProps)(Field)
