import React from 'react'
import classes from './Fields.module.css'
import Field from '../../Question/Field/Field'
import questionsFields from '../../../../data/questions/questionsFields.json'
import { Link } from 'gatsby'
import { connect } from 'react-redux'

const fields = props => {
  let fs = []
  for (let field in questionsFields) fs.push(field)

  const click = e => {
    const fieldClicked = e.currentTarget.textContent

    if (
      fieldClicked === props.lockedField ||
      props.paidAccessUntil < Date.now()
    )
      e.preventDefault()
  }

  return (
    <div className={classes.Container}>
      <center>
        <h1 className={classes.Heading}>FIELDS</h1>
      </center>
      <div className={classes.Description}>
        <span>
          Click on the Field that you would like to lock and you will be able to
          practice questions from this particular Field only. To unlock the
          Field just click on it one more time or hit 'PRACTICE' button in the
          navigation bar.
        </span>
      </div>
      <ul className={classes.List}>
        {fs.map(f => {
          const randomQuestion =
            questionsFields[f][
              Math.floor(Math.random() * questionsFields[f].length)
            ]

          return (
            <li key={f}>
              <Link to={`/${randomQuestion}`} onClick={click} text={f}>
                <Field text={f} />
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    lockedField: state.field.locked,
    paidAccessUntil: state.user.paidAccessUntil
  }
}

export default connect(mapStateToProps, null)(fields)
